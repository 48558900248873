.app {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: lighten($mint-cream, 2%);

  & > * {
    align-self: stretch;
  }

  main {
    margin: 0 auto;
  }

  footer {
    margin-top: auto;
    text-align: center;
    padding-bottom: 32px;
    font-size: 60%;
  }

  @media (min-width: 768px) {
    main {
      margin: auto;
    }
  }
}
