/*  COLORS  */

$black: #222222;
$mint-cream: #eff9f0;
$nickel: #626c66;
$light-coral: #ef6f6c;
$light-coral-soft: lighten($light-coral, 10%);
$marigold: #eca400;

/*  FONT-SIZE   */

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

$padding_x: 32px;
$padding_y: 64px;

/*  ICONS   */

$icomoon-font-family: "kaeptn" !default;
$icomoon-font-path: "fonts" !default;

$icon-error: "\e900";
$icon-warning: "\e901";
$icon-album: "\e902";
$icon-fast_forward: "\e903";
$icon-fast_rewind: "\e904";
$icon-games: "\e905";
$icon-loop: "\e906";
$icon-pause: "\e907";
$icon-pause_circle_filled: "\e908";
$icon-play_arrow: "\e909";
$icon-play_circle_filled: "\e90a";
$icon-playlist_add: "\e90b";
$icon-skip_next: "\e90c";
$icon-skip_previous: "\e90d";
$icon-stop: "\e90e";
$icon-volume_down: "\e90f";
$icon-volume_mute: "\e910";
$icon-volume_off: "\e911";
$icon-volume_up: "\e912";
$icon-playlist_play: "\e913";
$icon-subscriptions: "\e914";
$icon-playlist_add_check: "\e915";
$icon-queue_play_next: "\e916";
$icon-remove_from_queue: "\e917";
$icon-branding_watermark: "\e918";
$icon-control_camera: "\e919";
$icon-chat: "\e91a";
$icon-comment: "\e91b";
$icon-email: "\e91c";
$icon-vpn_key: "\e91d";
$icon-mail_outline: "\e91e";
$icon-add_box: "\e91f";
$icon-add_circle: "\e920";
$icon-clear: "\e921";
$icon-create: "\e922";
$icon-flag: "\e923";
$icon-forward: "\e924";
$icon-remove_circle: "\e925";
$icon-file_copy: "\e926";
$icon-how_to_reg: "\e927";
$icon-border_color: "\e928";
$icon-insert_drive_file: "\e929";
$icon-file_download: "\e92a";
$icon-file_upload: "\e92b";
$icon-folder: "\e92c";
$icon-keyboard_arrow_down: "\e92d";
$icon-keyboard_arrow_left: "\e92e";
$icon-keyboard_arrow_right: "\e92f";
$icon-keyboard_arrow_up: "\e930";
$icon-keyboard_capslock: "\e931";
$icon-security: "\e932";
$icon-looks_3: "\e933";
$icon-looks_4: "\e934";
$icon-looks_5: "\e935";
$icon-looks_6: "\e936";
$icon-looks_one: "\e937";
$icon-looks_two: "\e938";
$icon-arrow_drop_down: "\e939";
$icon-arrow_drop_down_circle: "\e93a";
$icon-arrow_drop_up: "\e93b";
$icon-cancel: "\e93c";
$icon-check: "\e93d";
$icon-fullscreen: "\e93e";
$icon-fullscreen_exit: "\e93f";
$icon-menu: "\e940";
$icon-keyboard_control: "\e941";
$icon-more_vert: "\e942";
$icon-unfold_less: "\e943";
$icon-unfold_more: "\e944";
$icon-first_page: "\e945";
$icon-last_page: "\e946";
$icon-arrow_left: "\e947";
$icon-arrow_right: "\e948";
$icon-arrow_back_ios: "\e949";
$icon-arrow_forward_ios: "\e94a";
$icon-sim_card_alert: "\e94b";
$icon-sms_failed: "\e94c";
$icon-ondemand_video: "\e94d";
$icon-priority_high: "\e94e";
$icon-person: "\e94f";
$icon-person_add: "\e950";
$icon-share: "\e951";
$icon-check_box: "\e952";
$icon-check_box_outline_blank: "\e953";
$icon-radio_button_on: "\e954";
$icon-account_box: "\e955";
$icon-account_circle: "\e956";
$icon-delete: "\e957";
$icon-home: "\e958";
$icon-https: "\e959";
$icon-info: "\e95a";
$icon-label: "\e95b";
$icon-note_add: "\e95c";
$icon-perm_contact_calendar: "\e95d";
$icon-settings: "\e95e";
$icon-verified_user: "\e95f";
$icon-http: "\e960";
$icon-toggle_off: "\e961";
$icon-toggle_on: "\e962";
$icon-double_arrow: "\e963";
$icon-privacy_tip: "\e964";
$icon-contact_page: "\e965";
