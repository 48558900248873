header {
  padding: $padding_y $padding_x;
  margin: 0 auto;
  text-align: center;
  .logo {
    width: 200px;
    display: inline-block;
    svg {
      .prefix__cls-2 {
        fill: $black;
      }
    }
  }
}
