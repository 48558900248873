input {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 2;
  text-align: center;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  &:focus {
    outline: none;
  }
}

::placeholder {
  color: lighten($nickel, 22%);
}

:-ms-input-placeholder {
  color: lighten($nickel, 22%);
}

::-ms-input-placeholder {
  color: lighten($nickel, 22%);
}

input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px lighten($nickel, 10%) inset !important;
  -webkit-box-shadow: 0 0 0 30px lighten($nickel, 10%) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: lighten($nickel, 46%) !important;
}

button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="submit"] {
  border: none;
  background-color: transparent;
  color: whitesmoke;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
}

// input[type="text"],
// input[type="password"],
// input[type="email"] {
//   text-indent: 10px;
// }

input[type="submit"] {
  cursor: pointer;
  font-weight: 500;
}

.form-element {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 0 auto 16px;
  &::before {
    content: "";
    display: inline-block;
    width: 56px;
    height: 48px;
    background-color: transparent;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    border: 2px solid $black;
    border-right: none;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 56px;
    height: 48px;
    background-color: transparent;
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
    border: 2px solid $black;
    border-left: none;
  }

  #username {
    width: 186px;
    border-radius: 0;
    background-color: $black;
    color: lighten($nickel, 46%);
    &::placeholder {
      color: lighten($black, 28%);
    }

    &:-ms-input-placeholder {
      color: lighten($black, 28%);
    }

    &::-ms-input-placeholder {
      color: lighten($black, 28%);
    }
  }

  #password,
  #stream-url {
    border-radius: 0;
    width: 186px;
    //background-color: lighten($mint-cream, 2%);
    // box-shadow: inset 0 0 0 2px lighten($nickel, 10%);
    box-shadow: inset 0 2px 0 $black, inset 0 -2px 0 $black;
    color: lighten($nickel, 10%);
    &::placeholder {
      color: lighten($nickel, 38%);
    }

    &:-ms-input-placeholder {
      color: lighten($nickel, 38%);
    }

    &::-ms-input-placeholder {
      color: lighten($nickel, 38%);
    }
  }

  #submit {
    background-color: $black;
    color: lighten($nickel, 44%);
    transition: all 0.3s linear;
    margin-top: 8px;
    width: 298px;
    &:hover {
      background-color: lighten($black, 5%);
    }
  }

  .icon {
    position: absolute;
    top: 14px;
    left: 26px;
    // margin-left: 24px;
  }

  .icon_btn {
    position: absolute;
    top: 14px;
    left: 86%;
    cursor: pointer;
    text-align: right;
    max-width: 28px;
  }

  .icon-person {
    color: lighten($nickel, 46%);
  }

  .icon-vpn_key {
    color: $black;
    transform: rotate(-30deg);
  }
}

.form-element.nickel {
  &::before {
    background-color: $black;
  }
  &::after {
    background-color: $black;
  }
}

.form-element.hidden-before-after {
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}
